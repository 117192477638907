<template>
    <div class="store">
        <div class="menu">
            <span class="item" :class="{ active: Active == 'Information'}" @click="Active = 'Information'">Algemeen</span>
            <span class="item" :class="{ active: Active == 'Team'}" @click="Active = 'Team'">Team</span>
            <span class="item" :class="{ active: Active == 'Locations'}" @click="Active = 'Locations'">Locaties</span>
            <span class="item" :class="{ active: Active == 'Website'}" @click="Active = 'Website'">Website</span>
            <span class="item" :class="{ active: Active == 'Narrowcasting'}" @click="Active = 'Narrowcasting'">Narrowcasting</span>
        </div>
        <div class="block" v-if="Active == 'Information'">
            <div class="group">
                <span class="subtitle">Adres</span>
                <div class="sub-group">
                    <span>Straat</span>
                    <input type="text" v-model="Store.Street">
                </div>
                <div class="sub-group">
                    <span>Huisnummer</span>
                    <input type="text" v-model="Store.Number">
                </div>
                <div class="sub-group">
                    <span>Postcode</span>
                    <input type="text" v-model="Store.Postcode">
                </div>
                <div class="sub-group">
                    <span>Stad</span>
                    <input type="text" v-model="Store.City">
                </div>
                <div class="sub-group">
                    <span>Land</span>
                    <input type="text" v-model="Store.Country">
                </div>
            </div>
            <div class="group">
                <span class="subtitle">Openingstijden</span>
                <div class="sub-group">
                    <span>Maandag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Dinsdag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Woensdag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Donderdag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Vrijdag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Zaterdag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
                <div class="sub-group">
                    <span>Zondag</span>
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.Start" placeholder="Vanaf (00:00)">
                    <input class="half" type="text" v-model="Store.OpeningHours.Monday.End" placeholder="Tot (23:59)">
                </div>
            </div>
        </div>
        <div class="block" v-if="Active == 'Website'">
            <div class="group">
                <span class="subtitle">Kleuren</span>
                <div class="sub-group">
                    <span>Hoofdkleur</span>
                    <input type="text" v-model="Store.PrimaryColor" placeholder="#0e0e0e">
                </div>
                <div class="sub-group">
                    <span>Accentkleur</span>
                    <input type="text" v-model="Store.SecondaryColor" placeholder="#0e0e0e">
                </div>
            </div>
        </div>
        <div class="block" v-if="Active == 'Narrowcasting'">
            <div class="group" v-for="(Item, i) in Narrowcasting.Items" :key="i">
                <span class="subtitle">{{ Item.Code }}</span>

                <draggable v-model="Item.Items" class="media" item-key="id">
                    <template #item="{element, index}">
                        <div class="media-item" :style="{ background: '#fff url(' + element.ImageURL + ')' }">
                            <svg v-if="element.Type == 'webm'" class="video" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M16 10L18.5768 8.45392C19.3699 7.97803 19.7665 7.74009 20.0928 7.77051C20.3773 7.79703 20.6369 7.944 20.806 8.17433C21 8.43848 21 8.90095 21 9.8259V14.1741C21 15.099 21 15.5615 20.806 15.8257C20.6369 16.056 20.3773 16.203 20.0928 16.2295C19.7665 16.2599 19.3699 16.022 18.5768 15.5461L16 14M6.2 18H12.8C13.9201 18 14.4802 18 14.908 17.782C15.2843 17.5903 15.5903 17.2843 15.782 16.908C16 16.4802 16 15.9201 16 14.8V9.2C16 8.0799 16 7.51984 15.782 7.09202C15.5903 6.71569 15.2843 6.40973 14.908 6.21799C14.4802 6 13.9201 6 12.8 6H6.2C5.0799 6 4.51984 6 4.09202 6.21799C3.71569 6.40973 3.40973 6.71569 3.21799 7.09202C3 7.51984 3 8.07989 3 9.2V14.8C3 15.9201 3 16.4802 3.21799 16.908C3.40973 17.2843 3.71569 17.5903 4.09202 17.782C4.51984 18 5.07989 18 6.2 18Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                            <svg v-if="!element.Identifier" class="add" @click="this.$root.OpenMedia(element, 'Image')" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g><g><g data-name="add" id="add-2"> <g> <line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>
                            <svg v-if="element.Identifier" class="delete" @click="RemoveItem(i, index)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M10 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        </div>
                    </template>
                </draggable>

                <div class="buttons">
                    <span class="save" @click="SaveNarrowcasting(Item.Code, Item.Items)">Opslaan</span>
                    <span class="delete" @click="DeleteNarrowcasting(Item.Code)">Verwijderen</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'StoresItem',

    components: {
        draggable
    },

    data () {
        return {
            Active: 'Narrowcasting',
            Store: {
                Street: '',
                Number: '',
                Postcode: '',
                City: '',
                Country: '',
                OpeningHours: {
                    Monday: {
                        Start: '',
                        End: ''
                    }
                }
            },
            Narrowcasting: {
                Items: [
                    {
                        Identifier: 'c015f6e6-0db3-4966-ac34-581ca598d642',
                        Code: 'P30Y55RTR1',
                        Items: [
                            {
                                Active: false,
                                Identifier: false
                            }
                        ],
                        Created: '2024-08-06 11:21:50.000000'
                    }
                ]
            }
        }
    },

    mounted () {
        this.GetStore()
        
        var self = this
        this.$mitt.on('ImageSelected', (e) => { self.ChangeMedia(e); })
    },

    methods: {
        GetStore () {
            this.$http.get(`https://api.wheels.nl/stores/${this.$route.params.id}`).then((response) => {
                if(response.data.narrowcasting) { this.Narrowcasting.Items = response.data.narrowcasting }
            })
        },

        SaveNarrowcasting (e, a) {
            this.$http.patch(`https://api.wheels.nl/narrowcasting/${e}`, {
                Items: a
            }).then(() => {
                this.$toast.open({
                    message: 'Item opgeslagen',
                    position: 'bottom-right',
                    type: 'success',
                    duration: 2500
                })
            })
        },

        DeleteNarrowcasting (e) {
            this.$http.delete(`https://api.wheels.nl/narrowcasting/${e}`).then(() => {
                this.$toast.open({
                    message: 'Item verwijderd',
                    position: 'bottom-right',
                    type: 'success',
                    duration: 2500
                })
            })
        },

        ChangeMedia (e) {
            this.Narrowcasting.Items.forEach((a) => {
                a.Items.forEach((z) => {
                    if(z.Active == e.Focus) {
                        z.Identifier = e.Selected

                        this.SetMedia(z, e.Selected, a)
                    }
                })
            })
        },

        SetMedia (e, a, z) {
            this.$http.get(`https://api.wheels.nl/pages/media/${a}`).then((response) => {
                e.Image = a,
                e.ImageURL = response.data.Image
                e.Type = response.data.Type
                e.Alt = response.data.Alt

                if(response.data.Video) {
                    e.Video = response.data.Video
                    e.Duration = response.data.Duration
                } else {
                    e.Duration = 15
                }
            })

            z.Items.push({
                Active: false,
                Identifier: false
            })
        },

        RemoveItem (e, a) {
            this.Narrowcasting.Items[e].Items.splice(a, 1)
        }
    }
}
</script>

<style scoped>
.store {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: 100%;
    padding: 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: auto
}

.store .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.store .menu .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: 50px;
    border-bottom: solid 2px #d7d7d7;
    cursor: pointer;
}

.store .menu .item.active {
    color: #0171d7;
    font-weight: 700;
    border-bottom: solid 2px #0171d7;
}





.store .block {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 25px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.store .block .subtitle {
    font-size: 20px;
    font-weight: 500;
}

.store .block .group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 5px;
}

.store .block .group .sub-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
}

.store .block .group .sub-group span {
    font-size: 14px;
    width: 25%;
}

.store .block .group .sub-group input {
    width: 75%;
    height: 40px;
    padding: 0 35px 0 10px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    outline: 0;
}

.store .block .group .sub-group input.half {
    width: calc(75% / 2);
}

.store .block .group .media {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.store .block .group .media-item {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 1px #797979;
    border-radius: 5px;
}

.store .block .group .media .media-item .add {
    width: 96px;
    height: 96px;
    padding: 24px;
    cursor: pointer;
}

.store .block .group .media .media-item .video {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
}

.store .block .group .media .media-item .delete {
    display: none;
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.store .block .group .media .media-item:hover .delete {
    display: block;
}

.store .block .group .buttons {
    display: flex;
    gap: 25px;
}

.store .block .group .buttons .save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 150px;
    height: 50px;
    background: #0071d7;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}

.store .block .group .buttons .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 150px;
    height: 50px;
    background: #cd5c5c;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}
</style>